/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useParams } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ForumOutlined from '@mui/icons-material/ForumOutlined';
import { useQuill } from 'react-quilljs';
import useGetLoggedInUserId from '../hooks/useGetLoggedInUserId';
import useGetLoggedInUser from '../hooks/useGetLoggedInUserDetails';
import MainContent from '../Components/MainContent';
import Spinner from '../Components/spinner/Spinner';
import Message from '../Components/Message';
import RightBar from '../Components/RightBar';
import Calendar from '../Components/calendar/Calendar';
import CommunityLanding from '../Components/community/CommunityLanding';
import CommuniyPostListItem from '../Components/community/CommuniyPostListItem';
import useFetchData from '../hooks/useFetchData';

const baseURL =
  process.env.REACT_APP_WEB_SOCKET_URL ||
  'wss://api.lms.v2.powerlearnproject.org/websocket/api';

function ForumDetailPage() {
  const user_id = useGetLoggedInUserId();
  const user = useGetLoggedInUser();
  const username = `${user.firstname.trim()} ${user.lastname.trim()}`;

  const [socket, setSocket] = useState(null);

  const { forumId } = useParams();
  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { data } = useFetchData(['forum-posts', forumId], '/forum-posts', {
    params: { forum_id: forumId },
  });

  const { quill, quillRef, Quill } = useQuill({
    modules: { magicUrl: true, toolbar: false },
  });

  if (Quill && !quill) {
    // eslint-disable-next-line global-require
    const MagicUrl = require('quill-magic-url').default;
    Quill.register('modules/magicUrl', MagicUrl);
  }

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const token = localStorage.getItem('token');
    const ws =
      token &&
      new WebSocket(
        `${baseURL}/forum_chat?forum_id=${forumId}&Authorization=${token}`
      );

    ws.onopen = () => {
      setIsLoading(true);
    };

    ws.onmessage = (event) => {
      const res = JSON.parse(event.data) || [];
      const groupMessages = res.length
        ? res.filter((message) => message.forum_id === forumId)
        : [];
      setMessages(groupMessages);
      setIsLoading(false); // Set loading state to false when messages are received
    };

    ws.onerror = () => {
      // toast.error("Couldn't get messages. Please try again!");
      setIsLoading(false); // Set loading state to false when there's an error
    };

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, [forumId]);

  const handleSendMessage = () => {
    if (!quill) return;

    const plainText = quill.getText();
    const message = {
      user_name: username,
      message: plainText,
    };
    socket.send(JSON.stringify(message));
    setNewMessage('');
    quill.setText('\n');
  };

  const isEmpty = newMessage === '<p><br></p>' || newMessage === '';

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setNewMessage(quill.root.innerHTML);
      });
    }
  }, [quill, quillRef]);

  return (
    <>
      <MainContent>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/forums"
              className="flex items-center"
            >
              <ForumOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
              <p className="">Communities</p>
            </NavLink>
          </Breadcrumbs>
        </div>
        <CommunityLanding />
        {/* <div className="bg-white mt-5 p-3 rounded-md"> */}
        <div className="mt-4 rounded-md ">
          <div className="grid grid-cols-3 bg-[#DADADA] p-2 font-roboto">
            <h3>Question</h3>
            <h3>Posted By</h3>
            <h3>Replies</h3>
          </div>
          <CommuniyPostListItem
            id={1}
            title="Map Loading Problems"
            author="Jane Doe"
            date={new Date().toLocaleString()}
            replies={159}
          />
        </div>
        {/* <p className="font-semibold underline uppercase mb-5">
            DISCLAIMER: Messages will disappear after 7 days.
          </p>
          {isLoading && <Spinner />}
          <div className="bg-chatbg p-2 space-y-4">
            {messages?.map((message, index) => (
              <Message
                fromMe={message.user_id === user_id}
                key={`${message.id + index}`}
                text={message.message}
                sender={message.user_name}
                // timeStamp={message.created_at}
              />
            ))}
            <div className="mt-2 space-y-4">
              <div ref={quillRef} />
              <button
                className={` uppercase font-medium rounded-sm ${
                  isEmpty
                    ? 'bg-gray-500 text-black px-3 py-1 cursor-not-allowed'
                    : 'bg-claret-500 text-white px-3 py-1'
                }`}
                type="button"
                onClick={handleSendMessage}
                disabled={isEmpty}
              >
                Send
              </button>
            </div>
          </div> */}
        {/* </div> */}
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default ForumDetailPage;
