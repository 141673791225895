import React from 'react';
import communityPhoto from '../../Assets/img/communitybg.png';

function CommunityLanding() {
  return (
    <div className="bg-[#00999EEB] h-[250px] grid place-items-center mt-[110px] relative text-white">
      <div className="absolute bg-red- -top-[100px] ">
        <img
          src={communityPhoto}
          alt="community"
          className=" object-contain h-40"
        />
      </div>
      <div className="">
        <h3 className=" text-4xl text-white font-medium font-roboto">
          Power Learn Project Community
        </h3>
        <p className="text-center text-lg">Connecting Student Developers.</p>
      </div>
    </div>
  );
}

export default CommunityLanding;
