import React, { useEffect, useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import MainContent from '../Components/MainContent';
// import CommunityLanding from '../Components/community/CommunityLanding';
import CommunityPostItem from '../Components/community/CommunityPostItem';
import RightBar from '../Components/RightBar';
import Calendar from '../Components/calendar/Calendar';
import CreatePost from '../Components/community/CreatePost';
import Modal from '../Components/Modal';
import useFetchCommunityData from '../hooks/useFetchCommunityData';
import Spinner from '../Components/spinner/Spinner';

const postNotificationBaseUrl = `${
  process.env.REACT_APP_COMMUNITY_SOCKET_URL ||
  'wss://api.lms.v2.powerlearnproject.org/community/api'
}/new_post_event?Authorization=`;

// const socketConnection = new WebSocket(
//   `${postNotificationBaseUrl + localStorage.getItem('token')}`
// );

function MyCommunity() {
  const [newPosts, setNewPosts] = useState(false);
  // const [socket, setSocket] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const token = localStorage.getItem('token');
    const ws =
      token &&
      new WebSocket(
        `${postNotificationBaseUrl + localStorage.getItem('token')}`
      );

    ws.onopen = () => {
      // setIsLoading(true);
    };

    ws.onmessage = () => {
      // const res = JSON.parse(event.data) || [];
      setNewPosts(true);
      // setIsLoading(false); // Set loading state to false when messages are received
    };

    ws.onerror = () => {
      // toast.error("Couldn't get messages. Please try again!");
      // self(false); // Set loading state to false when there's an error
    };

    // setSocket(ws);

    return () => {
      ws.close();
    };
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const queryClient = useQueryClient();

  // const state = queryClient.getQueryState(['community-posts']);

  const { data, isLoading } = useFetchCommunityData(
    ['community-posts'],
    '/posts',
    {
      params: { $limit: 200 },
    },
    "couldn't get posts. please try again",
    true
  );

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleNewPosts = () => {
    queryClient.invalidateQueries(['community-posts']);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setNewPosts(false);
  };

  return (
    <>
      <MainContent>
        <div className=" fixed lg:w-3/5 pr-10 px-2 z-10 rounded-lg w-full ">
          <div className="bg-white px-2 py-5 rounded-lg flex w-full justify-between shadow-xl">
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon />}
            >
              <NavLink
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to="/"
                className="flex items-center"
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                <p>Home</p>
              </NavLink>
            </Breadcrumbs>
            <button
              onClick={toggleModal}
              type="button"
              className="px-4 py-1 bg-claret-500 rounded-md text-white font-roboto text-sm"
            >
              Create Post
            </button>
          </div>
          {newPosts && (
            <div
              onClick={handleNewPosts}
              className=" w-full -mt-2 text-white "
              style={{ zIndex: 100000 }}
            >
              <p className="bg-claret-500 w-fit px-4 py-1 rounded-full mx-auto   z-50">
                New Posts
              </p>
            </div>
          )}
        </div>
        <div className="mt-24 w-full relative">
          {isLoading && <Spinner />}
          {/* {pos} */}
          {/* <CommunityLanding /> */}
          {!isLoading &&
            data?.data?.posts &&
            data?.data?.posts.map((post) => (
              <CommunityPostItem key={post.id} post={post} />
            ))}
        </div>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Create Post"
      >
        <CreatePost toggleModal={toggleModal} />
      </Modal>
    </>
  );
}

export default MyCommunity;
