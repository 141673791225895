/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import logo from '../Assets/img/logo.png';
import { getNotifications } from '../features/notification/NotificationSlice';
import NotificationPopover from './NotificationPopover';
import NavPopover from './Popover';
import { logout } from '../features/auth/authSlice';
import useGetLoggedInUserId from '../hooks/useGetLoggedInUserId';

const notificationBaseUrl = `${
  process.env.REACT_APP_NOTIFICATION_SOCKET_URL ||
  'wss://api.lms.v2.powerlearnproject.org/notification/socket'
}/notification?Authorization=`;

const socketConnection = new WebSocket(
  `${notificationBaseUrl + localStorage.getItem('token')}`
);

function UpdatedNavbar() {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.auth.userDetails);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const loggedInUserId = useGetLoggedInUserId();

  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState({
    count: 0,
    messages: [],
  });

  useEffect(() => {
    socketConnection.onmessage = (event) => {
      setNotifications({
        count: JSON.parse(event.data)?.length || 0,
        messages: JSON.parse(event.data) || [],
      });
      dispatch(
        getNotifications({
          count: JSON.parse(event.data)?.length || 0,
          messages: JSON.parse(event.data) || [],
        })
      );
    };
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(logout());
    window.location.assign('/available-courses');
  };

  return (
    <div className="bg-white fixed flex flex-1 w-full max-w-7xl mx-auto top-5 py-3 px-6 justify-between items-center rounde rounded-md">
      <div className="cursor-pointer" onClick={() => navigate('/')}>
        <img src={logo} className="object-contain" width={90} alt="logo" />
      </div>{' '}
      {/* <div className="border-[1px] maxMd:hidden  border-[#636363]  px-4 text-[#636363] w-96 py-2 rounded-full flex items-center space-x-4 ">
        <SearchOutlinedIcon sx={{ fontSize: 20 }} />
        <input
          type="text"
          // value={courseTitle}
          className=" border-none  text-[#636363] text-sm p-1 focus:outline-none"
          placeholder="Search by Course Title"
          // onChange={(event) => {
          //   setVisibleModules(
          //     (prevState) =>
          //       prevState.filter((item) =>
          //         item.moduleName.includes(event.target.value)
          //       ) || prevState
          //   );
          //   // handleChange();
          // }}
        />
      </div> */}
      {isAuthenticated ? (
        <NavPopover
          width={50}
          height={50}
          firstName={userDetails?.firstname}
          lastName={userDetails?.lastname}
          profilePic={userDetails?.profile_image}
          logout={handleLogout}
          visitProfile={() => navigate(`/user/${loggedInUserId}`)}
        />
      ) : (
        <button
          className="text-white bg-claret-500 px-6 py-2 rounded-3xl flex items-center gap-2 font-medium "
          type="button"
          onClick={() => navigate('/login')}
        >
          Login
          <LocalLibraryOutlinedIcon sx={{ fontSize: 20 }} className="" />
        </button>
      )}
    </div>
  );
}

export default UpdatedNavbar;
