import React from 'react';
import QRCode from 'qrcode.react';
import { useSelector } from 'react-redux';
import useGetLoggedInUser from '../hooks/useGetLoggedInUserDetails';
import useFetchData from '../hooks/useFetchData';
import Spinner from './spinner/Spinner';
import useFetchDataV2 from '../hooks/useFetchDataV2';
// import useFetchData from '../hooks/useFetchData';

function ScholarShipCertificate({ scholarshipCohortId }) {
  const user = useGetLoggedInUser();

  const userId = useSelector((state) => state.auth.userDetails.id);

  const {
    data: scholarShipCohortCertSettingsData,
    isLoading: isScholarShipCohortCertSettingsDataLoading,
  } = useFetchDataV2(
    ['cohort-cert-settings', scholarshipCohortId],
    `/cohorts/${scholarshipCohortId}/cert-settings`,
    {},
    'Could not fetch cohort certificate settings. Please try again later',
    !!scholarshipCohortId
  );

  const { data: student, isLoading: isStudentLoading } = useFetchData(
    [userId, 'userId'],
    `/users/student/${userId}`,
    {},
    'We could not get your details, try again later',
    true
  );

  const arrayToString = (arr) => {
    if (arr.length === 0) return '';
    if (arr.length === 1) return arr[0];
    if (arr.length === 2) return arr.join(' and ');
    return `${arr.slice(0, -1).join(', ')} and ${arr[arr.length - 1]}`;
  };

  const modulesSpecialized =
    student?.data?.student?.modules_specialised &&
    Array.isArray(JSON.parse(student?.data?.student?.modules_specialised))
      ? arrayToString(JSON.parse(student?.data?.student?.modules_specialised))
      : student?.data?.student?.modules_specialised;

  const certificationDate = student?.data?.student?.cohort?.certification_date
    ? new Date(
        student?.data?.student?.cohort?.certification_date
      ).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })
    : null;

  const home_url =
    process.env.REACT_APP_HOME_URL ||
    'https://plpacademy.powerlearnproject.org';

  const verify = `${home_url}/verify-cert/${user.id}`;

  return (
    <div>
      {}
      <div className="relative font-bauer text-lg h-full">
        {isStudentLoading || isScholarShipCohortCertSettingsDataLoading ? (
          <div className="h-screen flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <>
            <img
              src={
                scholarShipCohortCertSettingsData?.data?.cert_setting
                  ?.cert_template
              }
              alt="certificate"
              className="object-contain"
            />
            <>
              <div
                className={
                  scholarShipCohortCertSettingsData?.data?.cert_setting
                    ?.name_style_classnames ?? ''
                }
                // className="absolute top-[41%] left-[25.5%] w-1/2 font-light font-poppins text-white text-4xl"
              >
                <p className="">
                  {user.firstname[0].toUpperCase() + user.firstname.slice(1)}{' '}
                  {user.middlename &&
                    user.middlename[0].toUpperCase() + user.middlename.slice(1)}
                  {user.lastname[0].toUpperCase() + user.lastname.slice(1)}
                </p>
              </div>
              <div
                className={
                  scholarShipCohortCertSettingsData?.data?.cert_setting
                    ?.specialization_style_classnames ?? ''
                }
                // className="absolute top-[48%] left-[25.5%] text-white font-poppins text-[16px] font-light max-w-xl"
              >
                <p className="">
                  Successfully completed a 16 -week program in Software
                  Development covering Python, Web Technologies, Database
                  Management, Dart With Flutter and Soft Skills
                  {modulesSpecialized ? (
                    <>
                      , with specialisation in&nbsp;
                      <span className="font-light">{modulesSpecialized}</span>.
                    </>
                  ) : (
                    <>.</>
                  )}
                </p>
              </div>
              {certificationDate && (
                <div
                  className={
                    scholarShipCohortCertSettingsData?.data?.cert_setting
                      ?.certification_date_style_classnames ?? ''
                  }
                  // className="absolute bottom-[31%] left-[25.5%] text-white font-poppins"
                >
                  <p>{certificationDate}</p>
                </div>
              )}
              <div
                className={
                  scholarShipCohortCertSettingsData?.data?.cert_setting
                    ?.valid_cert_qr_code_style_classnames ?? ''
                } // example "absolute bottom-[40%] left-[4%] w-fit space-y-1 text-xs"
              >
                <p className="text-xs text-white w-[35%] font-poppins">
                  Valid Certificate<span className="ml-1">ID</span>
                </p>
                <QRCode
                  value={verify}
                  size={128}
                  level="H"
                  includeMargin
                  renderAs="svg"
                />
              </div>
            </>
          </>
        )}
      </div>
    </div>
  );
}

export default ScholarShipCertificate;
