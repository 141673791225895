/* eslint-disable no-nested-ternary */

import React, { useState } from 'react';
import MaterialReactTable from 'material-react-table';
import useFetchData from '../hooks/useFetchData';

function TableWrapper({
  transform,
  columns,
  config = null,
  queryKey,
  url,
  errorMessage,
  state,
  tableData,
  noCache,
}) {
  const [pagination, setPagination] = useState({
    pageIndex: 0,

    pageSize: 5,
  });

  const { data, isLoading } = useFetchData(
    queryKey || url,
    url,
    {
      ...config,
      params: {
        ...(config?.params || {}),
      },
    },
    errorMessage || 'Error fetching data',
    // pagination,
    [pagination.pageSize, pagination.pageIndex],
    noCache,
    () => setPagination.pageIndex(0)
  );

  const data2 =
    transform === 'submissions'
      ? data?.data?.group_assignment_submissions
      : transform === 'assignments'
      ? data?.data?.group_assignment?.assignments
      : transform === 'quizes'
      ? data?.data?.student_quiz_results
      : transform === 'groups'
      ? data?.data?.groups
      : transform === 'my_groups'
      ? data?.data?.group_members
      : transform === 'members'
      ? data?.data?.group_members
      : transform === 'forums'
      ? data?.data?.forums
      : transform === 'individual-assignment'
      ? data?.data?.submissions
      : data?.data?.data;

  const rowCount =
    data?.data?.limit < data?.data?.total
      ? data?.data?.limit
      : data?.data?.total;

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={data2 || tableData || []}
        autoResetPageIndex
        enableRowSelection={false}
        onPaginationChange={setPagination}
        rowCount={rowCount || 0}
        state={{
          isLoading,
          pagination,
          state,
        }}
        muiLinearProgressProps={({ isTopToolbar }) => ({
          color: 'primary',
          variant: 'indeterminate',
          sx: {
            display: isTopToolbar ? 'block' : 'none',
          },
        })}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 20, 50],
        }}
      />
    </div>
  );
}

export default TableWrapper;
