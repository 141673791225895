/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router';

function CommuniyPostListItem({ id, title, author, date, replies }) {
  const navigate = useNavigate();

  return (
    <div
      //   onClick={() => navigate(`/community-post/${id}`)}
      className=""
    >
      <div className=" space-y-1">
        <h3 className="font-roboto">{title}</h3>
        <p className="text-xs">{date}</p>
      </div>
      <p>{author}</p>
      <p>{replies}</p>
    </div>
  );
}

export default CommuniyPostListItem;
