import React from 'react';
import WorkAndEducationItem from './WorkAndEducationItem';

function WorkAndEducation({ items, work }) {
  //   console.log('items', items);
  return (
    <div className="bg-[#EFEFEF]/50 py-5 pl-5 rounded-md pr-2">
      <h3 className="font-roboto text-lg mb-2">
        {work ? 'Work Experience' : 'Education'}
      </h3>
      <div className=" space-y-4">
        {items?.map(
          (item) =>
            item && (
              <WorkAndEducationItem
                key={item.id}
                image={item.image || ''}
                title={work ? item.position : item.course_taken}
                place={work ? item.organisation : item.institution}
                is_current={item.is_current}
                start_date={item.start_date}
                end_date={item.end_date}
                city="Nairobi"
                country="Kenya"
              />
            )
        )}
      </div>
    </div>
  );
}

export default WorkAndEducation;
