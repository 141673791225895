import { useState } from 'react';
import Popover from '@mui/material/Popover';
// import Avatar from '@mui/material/Avatar';
import { Badge, IconButton, Typography } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';

export default function NotificationPopover({
  count,
  messages,
  readNotifications,
  readNotification,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Badge color="primary" badgeContent={count}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="w-322px h-546px p-4 bg-gray-100">
          <List className="w-full">
            {messages.length > 0 ? (
              messages.map((message) => (
                <ListItem
                  key={message.id}
                  disablePadding
                  onClick={() => {
                    readNotification(message.id);
                    handleClose();
                  }}
                  className="w-full"
                  sx={{
                    maxHeight: '3em',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <ListItemButton className="w-full">
                    <ListItemText
                      primary={message.message}
                      primaryTypographyProps={{
                        style: { textTransform: 'capitalize' },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))
            ) : (
              <Typography className="px-2 text-center w-full">
                No new notifications
              </Typography>
            )}
            {messages.length > 0 && (
              <ListItem disablePadding className="w-full">
                <ListItemButton
                  onClick={() => {
                    handleClose();
                    readNotifications();
                  }}
                  className="w-full"
                >
                  <ListItemText
                    primary="View All Notifications"
                    primaryTypographyProps={{
                      style: {
                        textTransform: 'capitalize',
                        color: 'blue',
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </div>
      </Popover>
    </div>
  );
}
