import React, { useEffect, useState } from 'react';
// import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
// import { useNavigate } from 'react-router';
// import logo from '../Assets/img/logo.png';
import LandingPagePaidModule from '../Components/LandingPagePaidModule';
import LeftBarLandingPage from '../Components/LeftBarLandingPage';
import LandingPageScholarshipCourse from '../Components/LandingPageScholarshipCourse';
import ModuleSkeleton from '../Components/ModuleSkeleton';
import UpdatedNavbar from '../Components/UpdatedNavbar';

const baseURL =
  process.env.REACT_APP_BASE_URL ||
  'https://api.lms.v2.powerlearnproject.org/gateway/api';

const courseFilterId =
  process.env.REACT_APP_LANDING_PAGE_COURSE_ID || '62fbec0d28ac4762bc524f91';

function LandingPage() {
  const getScholarshipCourse = async () => {
    const response = await axios.get(`${baseURL}/courses/${courseFilterId}`);

    return response;
  };

  const getPaidModules = async () => {
    const response = (
      await axios.get(`${baseURL}/course-modules`, {
        params: { is_paid_module: true },
      })
    ).data;
    return response;
  };
  // const navigate = useNavigate();
  const { data: courseData, isLoading } = useQuery(
    ['courses', courseFilterId],
    getScholarshipCourse,
    {
      staleTime: 3600 * 10000,
      cacheTime: 3600 * 10000,
      enabled: !!courseFilterId,
    }
  );

  const { data: paidModules, isLoading: isLoadingPaidModules } = useQuery(
    ['paid-modules'],
    getPaidModules,
    {
      staleTime: 3600 * 10000,
      cacheTime: 3600 * 10000,
    }
  );

  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [visiblePaidModules, setVisiblePaidModules] = useState([]);

  useEffect(() => {
    if (selectedCategory === 'All Categories') {
      setVisiblePaidModules(paidModules?.course_modules);
    } else {
      setVisiblePaidModules(
        paidModules?.course_modules.filter(
          (course_module) => course_module.category === selectedCategory
        )
      );
    }
  }, [paidModules?.course_modules, selectedCategory]);

  return (
    <div className=" max-w-7xl h-screen mx-auto flex flex-col gap-5  relative  ">
      <UpdatedNavbar />
      <div className="grid grid-cols-1 lg:grid-cols-6 gap-4 h-full fixed left-0 right-0 max-w-7xl mx-auto top-[8rem]">
        <LeftBarLandingPage setSelectedCategory={setSelectedCategory} />
        <div className="col-span-5 overflow-y-scroll gap-4  grid grid-cols-1 lg:grid-cols-4 xl:grid-cols- 4 md:grid-cols-2 mb-40">
          {!isLoading && !isLoadingPaidModules && courseData?.data?.course && (
            <LandingPageScholarshipCourse course={courseData?.data?.course} />
          )}
          {!isLoading &&
          !isLoadingPaidModules &&
          visiblePaidModules?.length >= 1 ? (
            visiblePaidModules?.map((module) => (
              <LandingPagePaidModule key={module.id} module={module} />
            ))
          ) : (
            <div className="col-span-5 gap-4  grid grid-cols-1 lg:grid-cols-4 xl:grid-cols- 4 md:grid-cols-2 mb-40">
              {isLoading || isLoadingPaidModules ? (
                Array.from(new Array(8)).map((item) => (
                  <ModuleSkeleton key={item} />
                ))
              ) : (
                <div className="col-span-5 ">
                  <h3 className="text-xl text-center font-semibold capitalize">
                    {selectedCategory === 'All Categories'
                      ? ' Paid courses coming soon'
                      : ` Courses on ${selectedCategory} coming soon`}
                  </h3>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
