import React, { useEffect, useState } from 'react';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import logo from '../Assets/img/logo.png';
import LeftBarLandingPage from '../Components/LeftBarLandingPage';
import ModuleSkeleton from '../Components/ModuleSkeleton';
import LandingPageScholarshipModule from '../Components/LandingPageScholarshipModule';

const baseURL =
  process.env.REACT_APP_BASE_URL ||
  'https://api.lms.v2.powerlearnproject.org/gateway/api';

const courseFilterId =
  process.env.REACT_APP_LANDING_PAGE_COURSE_ID || '62fbec0d28ac4762bc524f91';

const getScholarshipModules = async () => {
  const response = (
    await axios.get(`${baseURL}/course-modules`, {
      params: {
        courseId: courseFilterId,
      },
    })
  ).data;
  return response;
};

function LandingPage() {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    ['scholarship-modules', courseFilterId],
    getScholarshipModules,
    {
      staleTime: 3600000,
    }
  );

  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [visibleModules, setVisibleModules] = useState([]);

  useEffect(() => {
    if (selectedCategory === 'All Categories') {
      setVisibleModules(data?.course_modules);
    } else {
      setVisibleModules(
        data?.course_modules.filter(
          (course_module) => course_module.category === selectedCategory
        )
      );
    }
  }, [data?.course_modules, selectedCategory]);

  return (
    <div className=" max-w-7xl h-screen mx-auto flex flex-col gap-5  relative  ">
      <div className="bg-white fixed flex flex-1 w-full max-w-7xl mx-auto top-5 py-3 px-6 justify-between items-center rounde rounded-full">
        <div className="cursor-pointer" onClick={() => navigate('/')}>
          <img src={logo} className="object-contain" width={90} alt="logo" />
        </div>{' '}
        <div className="border-[1px] maxMd:hidden  border-[#636363]  px-4 text-[#636363] w-96 py-2 rounded-full flex items-center space-x-4 ">
          <SearchOutlinedIcon sx={{ fontSize: 20 }} />
          <input
            type="text"
            // value={courseTitle}
            className=" border-none  text-[#636363] text-sm p-1 focus:outline-none"
            placeholder="Search by Course Title"
          />
        </div>
        <button
          className="text-white bg-claret-500 px-6 py-2 rounded-3xl flex items-center gap-2 font-medium "
          type="button"
          onClick={() => navigate('/login')}
        >
          Login
          <LocalLibraryOutlinedIcon sx={{ fontSize: 20 }} className="" />
        </button>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-6 gap-4 h-full fixed left-0 right-0 max-w-7xl mx-auto top-[8rem]">
        <LeftBarLandingPage setSelectedCategory={setSelectedCategory} />
        <div className="col-span-5 overflow-y-scroll gap-4  grid grid-cols-1 lg:grid-cols-4 xl:grid-cols- 4 md:grid-cols-2 mb-40">
          {visibleModules?.length >= 1 ? (
            visibleModules?.map((module) => (
              <LandingPageScholarshipModule
                scholarship
                key={module.id}
                module={module}
              />
            ))
          ) : (
            <div className="col-span-5 gap-4  grid grid-cols-1 lg:grid-cols-4 xl:grid-cols- 4 md:grid-cols-2 mb-40">
              {isLoading ? (
                Array.from(new Array(6)).map((item) => (
                  <ModuleSkeleton key={item} />
                ))
              ) : (
                <div className="col-span-5 ">
                  <h3 className="text-xl text-center font-semibold capitalize">
                    {selectedCategory === 'All Categories'
                      ? ' no courses available'
                      : ` Courses on ${selectedCategory} coming soon`}
                  </h3>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
