import React, { useState } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Spinner from './spinner/Spinner';
// import useFetchData from '../hooks/useFetchData';

const baseURL =
  process.env.REACT_APP_BASE_URL ||
  'https://api.lms.v2.powerlearnproject.org/gateway/api';

const getWeekLessons = async (weekId) => {
  const response = (
    await axios.get(`${baseURL}/week-lessons`, { params: { weekId } })
  ).data;
  return response;
};

function SingleWeek({ week, number, isActive, activeIndexHandler }) {
  // fetch lessons by course week
  const [selectedWeekId, setSelectedWeekId] = useState(null);

  const { data: lessons, isLoading } = useQuery(
    ['week-lessons', selectedWeekId],
    () => getWeekLessons(selectedWeekId),
    { enabled: !!selectedWeekId }
  );

  const hanldeClick = () => {
    activeIndexHandler(number, week.id);
    setSelectedWeekId(week.id);
  };

  return (
    <div className="">
      <div
        key={week.id}
        className={`flex space-x-4 border-[1px] border-gray-400 rounded-md py-4 px-5 cursor-pointer hover:bg-teal-500/10 align-middle items-center ${
          isActive ? 'border-2 border-teal-500' : ''
        }`}
        onClick={hanldeClick}
      >
        {isActive ? (
          <RemoveOutlinedIcon
            className=" text-teal-600"
            sx={{ fontSize: 18 }}
          />
        ) : (
          <AddOutlinedIcon sx={{ fontSize: 18 }} />
        )}
        <p className="text-base capitalize">{`Week ${number}: ${week.weekName}`}</p>
      </div>
      {isActive && isLoading && (
        <div className=" px-5 py-2 rounded-md">
          <Spinner />
        </div>
      )}
      {isActive &&
        !isLoading &&
        lessons?.lessons &&
        lessons?.lessons?.map((lesson, index) => (
          <div
            key={lesson.id}
            className={`px-5 py-2 rounded-md border-[1px] ${
              index % 2 === 0 ? 'bg-[#D0EFED]' : ''
            }`}
          >
            <p className="text-base capitalize">{`Lesson ${index + 1}: ${
              lesson.lessonTopic
            }`}</p>
          </div>
        ))}
      {isActive && !isLoading && !lessons?.lessons && (
        <div className="px-5 py-2 rounded-md border-[1px] text-center bg-[#D0EFED]">
          <p className="text-base capitalize">Lessons not available yet.</p>
        </div>
      )}
    </div>
  );
}

export default SingleWeek;
