import React from 'react';

const Categories = [
  { id: 1, name: 'Front-End' },
  { id: 2, name: 'Back-End' },
  { id: 3, name: 'Data Analytics' },
  { id: 4, name: 'Artificial Intelligence' },
  { id: 5, name: 'Cyber Security' },
  { id: 6, name: 'Entrepreneurship' },
];

function CategoryItem({ name, handleClick }) {
  return (
    <div
      className="bg-white py-2 px-4 rounded-xl border-[.2px] border-gray-300 cursor-pointer hover:shadow-xl hover:scale-110 ease-in-out duration-300 hover:bg-persian-500 hover:text-white"
      onClick={() => handleClick(name)}
    >
      <p className="text-sm">{name}</p>
    </div>
  );
}

function LeftBarLandingPage({ setSelectedCategory }) {
  const handleClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="hidden lg:flex">
      <div className="p-5 bg-white h-fit rounded-lg shadow-lg">
        <h3 className="font-semibold text-xl">Categories</h3>
        <hr className="my-4" />
        <div className="space-y-5">
          <CategoryItem name="All Categories" handleClick={handleClick} />
          {Categories.map((category) => (
            <CategoryItem
              key={category.id}
              name={category.name}
              handleClick={setSelectedCategory}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default LeftBarLandingPage;
